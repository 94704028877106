import React, { useState } from "react";
import { State } from "./State";

export const AppContext = React.createContext();

export const AppProvider = ({ children })=>{
    const [ state, setState ] = useState(new State());

    return(
        <AppContext.Provider value={ { state, setState } }>
            { children }
        </AppContext.Provider>
    )
}